@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth; 
}

::-webkit-scrollbar {
  display: none;
}

@layer base {
  body {
    @apply bg-gray-200 dark:bg-gray-900 overscroll-none ;
  }
}

.add-btn {
	@apply py-1.5 px-4 text-base font-medium text-[#51cbce] border border-[#51cbce] bg-white rounded-md shadow-md hover:bg-[#51cbce] hover:text-white transition-colors duration-200 ease-in-out;
}
.add-modal-btn {
	@apply mt-3 mb-3 text-white rounded-lg drop-shadow-lg font-Poppins py-3 px-3 bg-[#7c5082] border border-[#7c5082] hover:text-[#7c5082] hover:bg-white dark:hover:text-[#7c5082] dark:hover:bg-black;
}
.transfer-modal-btn {
  @apply mt-3 mb-3 text-white rounded-lg drop-shadow-lg font-Poppins px-3 bg-[#4CAF50] border border-[#4CAF50] hover:text-[#4CAF50] hover:bg-white dark:hover:text-[#4CAF50] dark:hover:bg-black;
}
.delete-req-btn {
	@apply py-1.5 px-4 text-base font-medium text-red-500 border border-red-500 bg-white rounded-md shadow-md hover:bg-red-500 hover:text-white transition-colors duration-200 ease-in-out;
}
.add-more-btn {
	@apply py-1.5 px-4 text-base font-medium text-[#353535] border border-[#353535] bg-white rounded-md shadow-md hover:bg-[#353535] hover:text-white transition-colors duration-200 ease-in-out mt-2;
}
/* action-button */
.del-btn {
	@apply px-2 py-1.5 bg-gray-400 rounded-md border border-gray-400 hover:border-gray-400 text-white dark:text-[#202829] hover:text-gray-400 dark:hover:text-gray-400 hover:bg-white dark:hover:bg-[#202829] shadow-md transition-all duration-200 ease-in-out;
}
.action-btn {
	@apply py-1.5 px-2 text-sm font-medium  text-white bg-blue-600 hover:bg-white hover:text-blue-600 rounded-md shadow-md transition-colors duration-200 ease-in-out;
}
.view-btn {
	@apply px-2 py-1.5 bg-[#4ec784] rounded-md border border-[#4ec784] hover:border-[#4ec784] text-white dark:text-[#202829] hover:text-[#4ec784] dark:hover:text-[#4ec784] hover:bg-white dark:hover:bg-[#202829] shadow-md transition-all duration-200 ease-in-out;
}
.edit-btn {
	@apply px-2 py-1.5 bg-[#9f77a4] rounded-md border border-[#9f77a4] hover:border-[#9f77a4] text-white dark:text-[#202829] hover:text-[#9f77a4] dark:hover:text-[#9f77a4] hover:bg-white dark:hover:bg-[#202829] shadow-md transition-all duration-200 ease-in-out;
}
.generate-btn {
	@apply px-2 py-1.5 bg-[#4361ee] rounded-md border border-[#4361ee] hover:border-[#4361ee] text-white dark:text-[#202829] hover:text-[#4361ee] dark:hover:text-[#4361ee] hover:bg-white dark:hover:bg-[#202829] shadow-md transition-all duration-200 ease-in-out;
}
/* modal action button */
.yes-btn {
	@apply px-4 py-1.5 bg-blue-500 rounded-md border border-blue-500 hover:border-blue-500 text-white hover:text-blue-500 hover:bg-white shadow-sm transition-all duration-200 ease-in-out;
}
.no-btn {
	@apply px-4 py-1.5 bg-gray-400 rounded-md border border-gray-400 hover:border-gray-400 text-white hover:text-gray-400 hover:bg-white shadow-sm transition-all duration-200 ease-in-out;
}
.extra-btn {
	@apply px-2 py-1.5 bg-green-100 rounded-md text-green-600 hover:text-green-500 shadow-md transition-all duration-200 ease-in-out;
}
/* table button */
.clear-btn {
	@apply bg-[#818486] hover:bg-white border border-[#818486] hover:border-[#353535] px-4 py-[8.5px] text-white hover:text-black font-Poppins rounded-md text-sm transition-all duration-200 ease-in-out;
}
.search-btn {
	@apply bg-[#9f77a4] hover:bg-white border border-[#9f77a4] hover:border-[#9f77a4] px-4 py-[8.5px] text-white hover:text-[#9f77a4] font-Poppins rounded-md text-sm transition-all duration-200 ease-in-out;
}


@layer utilities {
  .scrollbar-thin {
      scrollbar-width: thin;
  }
  .scrollbar-thumb-color {
      scrollbar-color: #7c5082 transparent;
  }
  .scrollbar-track-gray-200 {
      scrollbar-color: transparent #e5e7eb;
  }
  .dark .scrollbar-thumb-color {
      scrollbar-color: #cbd5e1 transparent;
  }
  .dark .scrollbar-track-color {
      scrollbar-color: transparent #101422;
  }
}




/* placeholder */
::placeholder {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
}

.dark ::placeholder {
  color: white;
}

/* datepicker style */
.datepicker-placeholder::placeholder {
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem; /* Tailwind equivalent for text-sm */
}

.custom-calendar {
  background-color: white; /* Default light mode background */
  color: black; /* Default light mode text color */
}

.dark .custom-calendar {
  background-color: #343a40;
  color: white;
}

.custom-calendar .react-datepicker__header {
  background-color: black; /* Use the calendar background color */
  color: white; /* Use the calendar text color */
}

.custom-calendar .react-datepicker__day,
.custom-calendar .react-datepicker__day-name,
.custom-calendar .react-datepicker__current-month,
.custom-calendar .react-datepicker-time__header,
.custom-calendar .react-datepicker-year-header {
  color: inherit; /* Use the calendar text color */
}

.custom-calendar .react-datepicker__navigation--previous,
.custom-calendar .react-datepicker__navigation--next {
  border-color: inherit; /* Use the calendar border color */
}

.custom-calendar .react-datepicker__day:hover {
  color: black; /* Text color on hover in light mode */
}

.dark .custom-calendar .react-datepicker__day:hover {
  color: black; /* Text color on hover in dark mode */
}

.table-row-border-black {
  border-bottom: 1px solid black;
}

